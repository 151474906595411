export const defaultParams = {
  utm_campaign: "#",
  utm_id: "#",
  utm_medium: "#",
  utm_source: "#",
};

// export default function useGetUtm() {
//   const urlSearchParams = !!window.location.search
//     ? new URLSearchParams(window.location.search)
//     : "";
//   const params = !!urlSearchParams
//     ? Object.fromEntries(urlSearchParams.entries())
//     : defaultParams;
//   return {
//     params,
//   };
// }
